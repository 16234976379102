import request from '@/utils/request'

export function getinstitutions(data) {
  return request({
    url: '/com/institutions?id='+data,
    method: 'get'
  })
}

export function institutionsTypeList() {
  return request({
    url: '/institutions/institutionsTypeList',
    method: 'get'
  })
}

export function technicalList() {
  return request({
    url: '/lookMoney/technicalList',
    method: 'get'
  })
}

export function updateinstitutions(data) {
  return request({
    url: '/com/institutions',
    method: 'put',
    params: data
  })
}

export function addinstitutions(data) {
  return request({
    url: '/com/institutions',
    method: 'post',
    params: data
  })
}

export function register(data) {
  return request({
    url: '/com/institutions/register',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
