<template>
  <div class="registered">
    <div
        :class="['talent-nav', { 'talent-no-index': this.activeNav !== 'home' }]"
    >
      <div class="talent-nav-content">
        <div class="talent-nav-top">
          <div class="talent-nav-logo">
            <img src="@/assets/image/index_logo.png" alt=""/>株洲分市场
          </div>
          <div class="talent-nav-btns" @click="go(-1)">
            返回首页
          </div>
        </div>
        <div class="talent-nav-search">
          <div class="talent-nav-search-heda">
            <div class="talent-nav-search-heda-text">
              <a-tabs :activeKey="activeKey" @change="tabChange">
                <a-tab-pane key="1" tab="机构注册" force-render>
                  <span style="margin-left: 48px;"><img src="@/assets/image/arrows.png" alt=""/></span>
                  <div style="display:flex">
                    <div style="width: 35%;margin-top: 39px">
                      <div>
                        <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol"
                                      :wrapper-col="wrapperCol">
                          <a-form-model-item label="机构分类" prop="dictId">
                            <a-select v-model="form.dictId" placeholder="请选择机构分类"
                                      style="width:435px;">
                              <a-select-option
                                  v-for="(item) in classificationList"
                                  :key="item.id">{{ item.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                          <a-form-model-item label="单位机构名称" prop="name">
                            <a-input v-model="form.name" style="width: 435px" placeholder="请输入单位机构名称"/>
                          </a-form-model-item>
                          <a-form-model-item label="企业代码" prop="validCodeAA">
                            <div style="display: flex;height: 46px;">
                              <div>
                                <a-form-model-item prop="codeType">
                                  <a-select v-model="form.codeType" placeholder="请选择代码类型"
                                            style="width:140px;margin-right: 5px;bottom: 4px">
                                    <a-select-option
                                        v-for="(item,index) in codeTypeList"
                                        :key="index+1"
                                    >
                                      {{ item }}
                                    </a-select-option>
                                  </a-select>
                                </a-form-model-item>
                              </div>
                              <div>
                                <a-form-model-item prop="code">
                                  <a-input v-model="form.code" style="width:290px" placeholder="请输入代码"/>
                                </a-form-model-item>
                              </div>
<!--                              <div style="margin-left: 10px;margin-right: 10px">-->
<!--                                <span>-</span>-->
<!--                              </div>-->
<!--                              <div>-->
<!--                                <a-form-model-item prop="zCode">-->
<!--                                  <a-input v-model="form.zCode" style="width:130px"/>-->
<!--                                </a-form-model-item>-->
<!--                              </div>-->
                            </div>
                          </a-form-model-item>
                          <a-form-model-item label="邮箱" prop="email">
                            <a-input v-model="form.email" max-length="30" style="width: 435px;" placeholder="请输入邮箱"/>
                          </a-form-model-item>
                          <a-form-model-item label="密码" prop="authPass" >
                            <a-input v-model="form.authPass" type="password" @keydown.native="keyDown" max-length="16" style="width: 435px" placeholder="请输入密码"/>
                          </a-form-model-item>
                          <a-form-model-item label="密码确认" prop="cPassword" >
                            <a-input v-model="form.cPassword" type="password" @keydown.native="keyDown" max-length="16" style="width: 435px" placeholder="请再次输入密码"/>
                          </a-form-model-item>
                          <a-form-model-item label="机构地址" prop="provinceAndCityArray">
                            <a-cascader :options="options" max-length="255" placeholder="请选择机构地址" v-model="form.provinceAndCityArray" @change="dataPickerChange" style="width: 435px;" />
                          </a-form-model-item>
                          <a-form-model-item label="详细地址" prop="detailedAddress">
                            <a-input v-model="form.detailedAddress" max-length="255" style="width: 435px" placeholder="请输入机构地址"/>
                          </a-form-model-item>
                          <a-form-model-item label="行业领域" prop="industryField">
                            <a-select v-model="form.industryField" placeholder="请选择行业领域"
                                      style="width:435px;">
                              <a-select-option v-for="(item) in technicalList"
                                               :key="item.id">{{ item.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                          <a-form-model-item label="联系人" prop="contact">
                            <a-input v-model="form.contact" max-length="255" style="width: 435px" placeholder="请输入联系人"/>
                          </a-form-model-item>
                          <a-form-model-item label="机构网址" prop="url">
                            <a-input v-model="form.url" max-length="255" style="width: 435px" placeholder="请输入机构网址"/>
                          </a-form-model-item>
                          <a-form-model-item label="认证文件" prop="certificationFile">
                            <a-upload
                                :multiple="true"
                                :file-list="fileList"
                                :customRequest="customRequest"
                                @change="handleChange"
                            >
                              <a-button>
                                <a-icon type="upload"/>
                                上传
                              </a-button>
                            </a-upload>
                          </a-form-model-item>
                          <a-form-model-item label="手机号" prop="mobilePhone">
                            <a-input v-model="form.mobilePhone"
                                     :max-length="Number(11)"
                                     @keydown="handleInput"
                                     style="width: 435px" placeholder="请输入手机号"/>
                          </a-form-model-item>
                          <a-form-model-item label="验证码" prop="validCodeAA">
                            <div style="display: flex">
                              <div>
                                <a-form-model-item prop="validCode">
                                  <a-input v-model="form.validCode" max-length="4" style="width: 335px" placeholder="请输入验证码"/>
                                </a-form-model-item>
                              </div>
                              <div>
                                <valid-code ref="validCodeA" :value.sync="vCode"></valid-code>
                              </div>
                            </div>
                          </a-form-model-item>
                          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                            <a-button  @click="go(-1)">
                              返回
                            </a-button>
                            <a-button type="primary" style="margin-left: 10px;" @click="onSubmit()">
                              提交
                            </a-button>
                          </a-form-model-item>
                        </a-form-model>
                        <a-modal v-model="visibleB" title="机构注册" @ok="handleOk(1)">
                          <p>是否确认提交？</p>
                          <p></p>
                          <p></p>
                        </a-modal>
                      </div>
                    </div>
                    <div class="tip_div">
                      <div class="content_cla">
                        <p>为验证您单位信息真实，请将以下证件扫描件上传至站点(不超过10M)：</p>
                        <p>1.组织机构代码/统一社会信用代码</p>
                        <p>2.企业法人营业执照</p>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="个体注册">
                  <span style="margin-left: 195px;"><img src="@/assets/image/arrows.png" alt=""/></span>
                  <div>
                    <div style="float: left;margin-top: 36px;">
                      <a-form-model ref="singleForm" :model="singleForm" :rules="rules2" :label-col="labelCol"
                                    :wrapper-col="wrapperCol">
                        <a-form-model-item label="邮箱" prop="email" >
                          <a-input v-model="singleForm.email" max-length="30" style="width: 435px;" placeholder="请输入邮箱"/>
                        </a-form-model-item>
                        <a-form-model-item label="密码" prop="password"  >
                          <a-input v-model="singleForm.password" max-length="16" @keydown.native="keyDown" type="password" style="width: 435px" placeholder="请输入密码"/>
                        </a-form-model-item>
                        <a-form-model-item label="密码确认" prop="cPassword" >
                          <a-input v-model="singleForm.cPassword" max-length="16" @keydown.native="keyDown" type="password" style="width: 435px" placeholder="请再次输入密码"/>
                        </a-form-model-item>
                        <a-form-model-item label="中文姓名" prop="chineseName">
                          <a-input v-model="singleForm.chineseName" max-length="255" style="width: 435px;" placeholder="请输入中文姓名"/>
                        </a-form-model-item>
                        <a-form-model-item label="所在地址" prop="provinceAndCityArray">
                          <a-cascader :options="options" max-length="255" placeholder="请选择所在地址" v-model="singleForm.provinceAndCityArray" style="width: 435px;"/>
                        </a-form-model-item>
                        <a-form-model-item label="学科分类" prop="subjectClassification">
                          <a-select v-model="singleForm.subjectClassification" placeholder="请选择学科分类"
                                    style="width:435px;">
                            <a-select-option v-for="(item) in subjectClassificationList"
                                             :key="item.id">{{ item.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                        <a-form-model-item label="当前工作机构" prop="organization">
                          <a-input v-model="singleForm.organization" max-length="255" style="width: 435px"  placeholder="请输入当前工作机构"/>
                        </a-form-model-item>
                        <a-form-model-item label="部门" prop="dept">
                          <a-input v-model="singleForm.dept" max-length="255" style="width: 435px" placeholder="请输入部门"/>
                        </a-form-model-item>
                        <a-form-model-item label="职称" prop="title">
                          <a-input v-model="singleForm.title" max-length="255" style="width: 435px" placeholder="请输入职称"/>
                        </a-form-model-item>
                        <a-form-model-item label="手机号" prop="mobilePhone">
                          <a-input v-model="singleForm.mobilePhone"
                                   :max-length="Number(11)"
                                   @keydown="handleInput"
                                   style="width: 435px" placeholder="请输入手机号"/>
                        </a-form-model-item>
                        <a-form-model-item label="验证码" prop="validCodeAA">
                          <div style="display: flex">
                            <div>
                              <a-form-model-item prop="validCode" >
                                <a-input v-model="singleForm.validCode" max-length="4" style="width: 335px" placeholder="请输入验证码"/>
                              </a-form-model-item>
                            </div>
                            <div>
                              <valid-code ref="validCode" :value.sync="vCode"></valid-code>
                            </div>
                          </div>
                        </a-form-model-item>
                        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                          <a-button  @click="go(-1)">
                            返回
                          </a-button>
                          <a-button type="primary" style="margin-left: 10px;" @click="onSubmit2()">
                            提交
                          </a-button>
                        </a-form-model-item>
                      </a-form-model>
                      <a-modal v-model="visibleA" title="个人注册" @ok="handleOk(2)">
                        <p>是否确认提交？</p>
                        <p></p>
                        <p></p>
                      </a-modal>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
            <div class="talent-nav-search-heda-text1" style="position: absolute;right: 15px;top: 18px;" @click="goLogin">已有账号？立即登录</div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
    <login ref="login"></login>
  </div>
</template>


<script>
import {regionData} from 'element-china-area-data'
import validCode from '@/components/identify'
import login from '../components/login'
// import {AreaCascader} from 'vue-area-cascader'
import {institutionsTypeList, technicalList, register} from '@/api/company/institutions'
import {ossUpload} from '../../api/uploader'
import {singleRegister, getSubject} from '../../api/user'
const form = {
  name: null,
  codeType: undefined,
  dictId: undefined,
  code: null,
  authPass: null,
  detailedAddress: null,
  industryField: undefined,
  contact: null,
  url: null,
  certificationFile: null,
  mobilePhone: null,
  provinceAndCityArray: [],
  cPassword: null,
  validCode: null,
}

const singleForm = {
  email: null,
  password: null,
  cPassword: null,
  chineseName: null,
  provinceAndCityArray: null,
  subjectClassification: undefined,
  organization: null,
  dept: null,
  title: null,
  mobilePhone: null,
  validCode: null,
}

export default {
  components: {validCode, login},
  name: "heda",
  data() {
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请正确填写邮箱'));
      } else {
        if (value !== '') {
          var reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
          if(!reg.test(value)){
            callback(new Error('请输入有效的邮箱'));
          }
        }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
      if (value === '' || value === null) {
        callback(new Error('请输入密码'));
      } else if(value.length < 6){
        callback(new Error('密码长度最小6位'));
      }else if(!reg.test(value)){
        callback(new Error('密码要同时含有数字和字母'));
      }else {
        callback();
      }
    };
    return {
      visibleA:false,
      visibleB:false,
      options: regionData,
      fList: [],
      fileList: [],
      codeTypeList: [
        '组织机构代码',
        '统一社会信用代码'
      ],
      classificationList: [],
      subjectClassificationList: [],
      technicalList: [],
      vCode: null,
      labelCol: {span: 10},
      wrapperCol: {span: 14},
      form: Object.assign({}, form),
      singleForm: Object.assign({}, singleForm),
      activeKey: "2",
      rules: {
        dictId: [{required: true, message: '请选择机构分类', trigger: 'blur'}],
        name: [{required: true, message: '请输入单位机构名称', trigger: 'blur'}],
        codeType: [{required: true, message: '请选择代码类型', trigger: 'blur'}],
        code: [{required: true, message: '请输入代码', trigger: 'blur'}],
        email: [{required: true,validator: validateEmail, trigger: 'blur'}],
        authPass: [{required: true,validator: validatePass, trigger: 'blur'}],
        cPassword: [{required: true,validator: validatePass, trigger: 'blur'}],
        provinceAndCityArray: [{required: true, message: '请选择省市区', trigger: 'blur'}],
        detailedAddress: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
        industryField: [{required: true, message: '请选择行业领域', trigger: 'blur'}],
        contact: [{required: true, message: '请输入联系人', trigger: 'blur'}],
        url: [{required: true, message: '请输入机构网址', trigger: 'blur'}],
        certificationFile: [{required: true, message: '请上传认证文件', trigger: 'blur'}],
        mobilePhone: [{required: true, message: '请输入手机号码', trigger: 'blur'}],
        validCode: [{required: true, message: '请输入验证码', trigger: 'blur'}],
        validCodeAA:[{required: true, message: '', trigger: 'blur'}],
      },
      rules2: {
        email: [{required: true,validator: validateEmail, trigger: 'blur'}],
        password: [{required: true,validator: validatePass, trigger: 'blur'}],
        cPassword: [{required: true,validator: validatePass, trigger: 'blur'}],
        chineseName: [{required: true, message: '请输入中文名称', trigger: 'blur'}],
        provinceAndCityArray: [{required: true, message: '请选择省市区', trigger: 'blur'}],
        subjectClassification: [{required: true, message: '请选择学科分类', trigger: 'blur'}],
        organization: [{required: true, message: '请输入当前工作机构', trigger: 'blur'}],
        dept: [{required: true, message: '请选择部门', trigger: 'blur'}],
        title: [{required: true, message: '请输入职称', trigger: 'blur'}],
        mobilePhone: [{required: true, message: '请输入手机号码', trigger: 'blur'}],
        validCode: [{required: true, message: '请输入验证码', trigger: 'blur'}],
        validCodeAA:[{required: true, message: '', trigger: 'blur'}],
      }
    }
  },
  methods: {
    keyDown(e){
      if (e.keyCode ==32){
        e.returnValue = false
      }
    },
    tabChange(activeKey){
      this.activeKey = activeKey
    },
    dataPickerChange(date){
      //console.log('🚀返回选择的省份详细信息：', date)
    },
    goLogin() {
      this.$refs.login.showSpanA();
      this.$refs.login.show(this.activeKey)
    },
    getSubjectHandle() {
      getSubject().then(res => {
        this.subjectClassificationList = res.data
      })
    },
    handleInput(e) {
      let a = e.key.replace(/[^\d]/g, "");
      if (!a && e.keyCode !== 8) {
        e.preventDefault();
      }
    },
    handleChange({file,fileList}) {
      // if(fileList.length>2){
      //   this.$message.success("只允许上传2张图片")
      //   return
      // }
      if(fileList.length>2){
        this.$message.success("只允许上传2个文件")
        return;
      }
       if(file.size/1048576 < 3) {
        fileList = fileList.slice(0,2);
        fileList = fileList.map(file => {
          file.status = 'done'
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        this.fileList = fileList
       }else {
         this.$message.success("文件过大")
       }
    },
    customRequest(data) {
        const formData = new FormData();
        formData.append("pic", data.file)
        ossUpload(formData).then(res => {
          if (this.fList.length ===2){
             this.fList =  this.fList.slice(1);
             this.fList.push(res.data.url)
          }else {
            this.fList.push(res.data.url)
          }
          this.form.certificationFile = this.fList.join(',')
        })

    },
    setFileList(obj) {
      this.form[obj.field] = obj.img_str
    },
    technicalListHandle() {
      technicalList().then(res => {
        this.technicalList = res.data
      })
    },
    institutionsTypeList() {
      institutionsTypeList().then(res => {
        this.classificationList = res.data
      })
    },
    //个人注册
    handleOk(data){
      if(data===1){
        register(this.form).then(res => {
          this.$refs.form.resetFields()
          this.fileList = []
          this.$message.success(res.msg)
          this.$refs.login.show(this.activeKey)
        })
      }
      if(data===2){
        singleRegister(this.singleForm).then(res => {
          this.$refs.singleForm.resetFields()
          this.$message.success(res.msg)
          this.$refs.login.show(this.activeKey)
        })
      }
      this.visibleA=false;
      this.visibleB=false;
    },
    onSubmit2() {
      this.$refs.singleForm.validate(valid => {
        if (valid) {
          if (this.singleForm.password !== this.singleForm.cPassword) {
            this.singleForm.cPassword = null
            this.$message.warning('密码确认错误,请重新输入确认密码');
            return
          }
          if (this.singleForm.provinceAndCityArray.length !== 3){
            this.$message.warning('请选择完整的机构地址')
            return;
          }
          if (this.singleForm.validCode.toLowerCase() !== this.vCode.toLowerCase()) {
            this.form.validCode = null
            this.$refs.validCode.refreshCode();
            this.$message.warning('验证码错误');
            return
          }
          this.visibleA=true;

        }
      })
    },
    //机构注册
    onSubmit() {
      this.$refs.form.validate(valid => {
        // if (this.fList !== null && this.fList.length !== 0) {
        //   //console.log(this.fList)
        //   //console.log('============================')
        //   this.form.certificationFile = this.fList.join(',')
        // }
        if (valid) {
          if (this.form.authPass !== this.form.cPassword) {
            this.form.cPassword = null
            this.$message.warning('密码确认错误,请重新输入确认密码');
            return
          }

          if (this.form.validCode.toLowerCase() !== this.vCode.toLowerCase()) {
            this.form.validCode = null
            this.$refs.validCodeA.refreshCode();
            this.$message.warning('验证码错误');
            return
          }
          if (this.form.provinceAndCityArray.length !== 3){
            this.$message.warning('请选择完整的机构地址')
            return;
          }
          this.visibleB=true;

        }
      })
    },
    go(infex) {
      this.$router.go(infex)
    }
  },
  mounted() {
    let params = this.$route.query.type
    if (params !== undefined){
      this.activeKey = params
    }
    this.getSubjectHandle()
    this.technicalListHandle()
    this.institutionsTypeList()
  }
}

</script>

<style lang="scss">
.registered {
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 21px;
    font-weight: 500;
  }

  .ant-tabs-bar {
    margin: unset;
  }

  .ant-cascader-picker-label {
    top: 50% !important;
  }

  .ant-cascader-picker .ant-input {
    height: 46px !important;
  }

  .ant-select-selection-selected-value {
    margin-top: 7px;
  }

  .ant-select-selection {
    border-radius: unset;
    border: 1px solid #E1E1E1;
  }

  .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
    font-size: 14px !important;
    margin-top: -4px !important;
  }

  .ant-select-selection--single {
    height: 46px;
  }

  .ant-input {
    height: 46px;
    border-radius: unset;
    border: 1px solid #E1E1E1;
  }

  .ant-col-offset-4 {
    margin-left: 57.666667%;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: unset;
  }

  .ant-tabs-ink-bar {
    background-color: unset;
  }

  .ant-tabs-bar {
    border-bottom: unset;
  }

  .ant-tabs {
    color: #FFFFFF;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #FFFFFF;
    font-size: 21px;
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: 6px 16px;
  }
}

</style>
<style lang="scss" scope>
.tip_div {
  padding: 10px;
  margin-left: 160px;
  margin-top: 801px;
  width: 540px;
  height: 98px;
  background: #FFF8ED;
  border: 1px solid #FFB94B;

  .content_cla {
    font-size: 16px;
    font-weight: normal;
    color: #FF9A02;
    line-height: 12px;


  }

}

.registered {
  position: relative;
  min-height: 100vh;

  .talent-nav {
    position: relative;

    .talent-bg {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      height: 317px;
    }

    .talent-nav-content {
      width: 1400px;
      margin: 0 auto;
      z-index: 2;
      padding-top: 20px;

      .talent-nav-top {
        display: flex;
        justify-content: space-between;
        color: #ffffff;

        .talent-nav-logo {
          font-size: 24px;
          font-weight: bold;

          img {
            padding-right: 20px;
          }
        }

        .talent-nav-btns {
          cursor: pointer;
          user-select: none;
          width: 120px;
          height: 40px;
          border: 1px solid #ffffff;
          border-radius: 20px;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
        }
      }

      .talent-nav-search {
        width: 1400px;
        height: 1300px;
        background: #FFFFFF;
        border: 2px solid #EFEFEF;

        .talent-nav-search-heda {
          width: 1399px;
          height: 70px;
          background: #075FEA;
          border: 2px solid #EFEFEF;
          position: relative;

          .talent-nav-search-heda-text {
            width: 100%;
            height: 21px;
            font-size: 22px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #FFFFFF;
            line-height: 36px;
            margin-left: 54px;
            padding-top: 17px;
            font-weight: 600;
          }

          .talent-nav-search-heda-text1 {
            width: 145px;
            height: 17px;
            font-size: 16px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #FFFFFF;
            line-height: 30px;
            float: right;
            margin-right: 34px;
            cursor: pointer;
          }
        }
      }
    }

    .talent-nav-bar {
      width: 100%;
      height: 65px;
      background: rgb(7, 95, 234);
      margin-top: 107px;

      .talent-nav-bar-list {
        width: 1400px;
        margin: 0 auto;
        height: 100%;
        display: flex;

        .active {
          background: #044fc6;
        }

        .talent-btn-bg {
          font-size: 22px;
          width: 320px;
          text-align: center;
          padding: 0;
        }

        .talent-nav-bar-item {
          padding: 20px 45px;
          font-size: 18px;
          color: #ffffff;
          user-select: none;
          cursor: pointer;
          margin-right: 10px;
        }

        .talent-nav-bar-item[href="#/home"] {
          padding: 20px 125px;
        }
      }
    }
  }

  .talent-content {
    width: 1400px;
    margin: 0 auto;
  }

  .talent-footer {
    width: 100%;
    height: 100px;
    background: #275db3;
    color: #ffffff;
    margin-top: 30px;
    position: relative;
    bottom: 0;
  }

  .talent-no-index {
    background: linear-gradient(0deg, #e0ebfd, #ffffff);

    .talent-bg {
      display: none;
    }

    .talent-nav-top {
      color: #333333 !important;

      .talent-nav-btns {
        background: #f6f6f6;
        color: #666666;
        border: 1px solid #dddddd !important;
      }
    }

    .talent-nav-search {
      margin-top: 30px !important;
    }

    .talent-nav-bar {
      margin-top: 34px;
    }
  }
}
</style>
